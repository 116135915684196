type Route = {
  ID: string;
  Name: string;
  Location: string;
};

// type RouteWithTitle = {
//   Name: string;
//   Location: string;
//   Title: string;
// };

type RouteTypes = {
  Blog: string;
  About: string;
  Pricing: string;
  BienEtre: string;
  Contact: string;
  Therapy: string;
};

const Routes: RouteTypes = {
  Blog: '/blog',
  About: '/about',
  Pricing: '/pricing',
  BienEtre: '/bienetre',
  Contact: '/contact',
  Therapy: '/therapy',
};

type SocialTypes = {
  Facebook: string;
  Instagram: string;
};

const SocialLinks: SocialTypes = {
  Facebook: 'https://www.facebook.com/clepsydrabienetre/',
  Instagram: 'https://www.instagram.com/clepsydra_bien_etre/',
};

const RoutesArray: Route[] = [
  {ID: '5', Name: 'Bien Etre', Location: Routes.BienEtre},
  {ID: '4', Name: 'Thérapie Bowen', Location: Routes.Therapy},
  {ID: '3', Name: 'Mon Parcours', Location: Routes.About},
  {ID: '1', Name: 'Tarifs', Location: Routes.Pricing},
  {ID: '2', Name: 'Contact', Location: Routes.Contact},
];


// const getRouteWithTitle = (title: string) => {
//   const routesWithTitle = {};
//   for (const stuff in Object.entries(RoutesArray)) {
//     routesWithTitle[stuff[stuff.location]] = stuff;
//   }
//   return routesWithTitle;
// };


export {Routes, SocialLinks, RoutesArray};
