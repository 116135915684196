import React from 'react';
import DefaultLayout from '../layout/DefaultLayout';
import MainPageContent from '../layout/MainPageContent';
import {RouteComponentProps} from '@reach/router';

type HomeProps = {
  children?: any;
} & RouteComponentProps;

function Home(props: HomeProps) {
  return (
    <DefaultLayout>
      {/* <Nav /> */}
      <MainPageContent />
      {/* <Testimonials /> */}
    </DefaultLayout>
  );
}

export default Home;
