import React from 'react';
import DefaultLayout from '../layout/DefaultLayout';
import {RouteComponentProps} from '@reach/router';

type NotFoundPageProps = {
  children?: any;
} & RouteComponentProps;

export default function NotFoundPage(props: NotFoundPageProps) {
  return <DefaultLayout title={'Not found page'}>404</DefaultLayout>;
}
