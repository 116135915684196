import React from 'react';
import BowenTherapy from '../components/BowenTherapy';
import DefaultLayout from '../layout/DefaultLayout';
import {RouteComponentProps} from '@reach/router';

type TherapyProps = {
  children?: any;
} & RouteComponentProps;

const Therapy = (props: TherapyProps) => {
  return (
    <DefaultLayout title={'Thérapie'} route={'therapy'}>
      <BowenTherapy />
    </DefaultLayout>
  );
};

export default Therapy;
