import React from 'react';
import AboutMe from '../components/AboutMe';
import DefaultLayout from '../layout/DefaultLayout';
import {RouteComponentProps} from '@reach/router';

type AboutPageProps = {
  children?: any;
} & RouteComponentProps;

export default function AboutPage(props: AboutPageProps) {
  return (
    <DefaultLayout title={'À propos de moi'} route={'about'}>
      <AboutMe />
    </DefaultLayout>
  );
}
