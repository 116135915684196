/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const BowenTherapy = () => {
  return (
    <div>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto  px-4 divide-y-2 divide-gray-200  sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-center mt-12 text-gray-900">
            Thérapie Bowen
          </h2>
          <div className="mt-6 pt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Donnez à la thérapie Bowen l'occasion de vous montrer ce que
                  votre corps à la capacité incroyable de faire.
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  En termes simples, cette thérapie initie la réponse de
                  guérison dans votre corps
                </dd>
              </div>
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  C’est quoi?
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  La Thérapie Bowen est une technique holistique de
                  manipulations douces et précises des muscles, tendons et
                  nerfs. L’essence même de cette méthode est de stimuler
                  l’organisme afin de redonner au corps son pouvoir
                  d’auto-guérison.
                </dd>
              </div>
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  A savoir
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Une thérapie douce et non-invasive, adapte à chacun, qui
                  traite efficacement la plupart des soucis de santé.
                </dd>
              </div>
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Origine
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  La méthode Bowen a été développée par l’australien Thomas A.
                  Bowen, afin de soulager son épouse qui souffrait de crises
                  d’asthme. Son désir de la soigner l’a amené à la découverte de
                  cette méthode thérapeutique innovante.
                </dd>
                <dd className="mt-2 text-base text-gray-500">
                  Il a transmis son savoir a Oswald et Elaine Rentsch qui, 4 ans
                  après le décès de Thomas Bowen, en 1986, ont créé la BOWEN
                  THERAPY ACADEMY OF AUSTRALIA, organisme officiel
                  d’enseignement et de certification.
                </dd>
              </div>

              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Quelles douleurs peuvent être soignées ?
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Problèmes musculosquelettiques et articulaires (genoux,
                  chevilles, pieds, épaules)
                  <p>Problèmes respiratoires, asthme, problèmes ORL</p>
                  <p>
                    Dysfonctionnements de l’appareil urinaire et reproducteur
                  </p>
                  <p>Accidents, traumas, entorses</p>
                  <p>Blocages émotionnels, problèmes d’insomnie</p>
                  <p>Douleurs liées à une maladie ou d’une opération</p>
                  <p>Maux de dos</p>
                  <p>Migraines, vertiges</p>
                  <p>
                    Douleurs du bassin, scoliose, jambes de longueurs
                    différentes
                  </p>
                </dd>
              </div>

              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  En quoi consiste la thérapie ?
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Lors de la première séance, un premier entretien oral est
                  nécessaire afin d’apprendre à mieux vous connaître. Chaque
                  corps est unique et s’exprime différemment et les séances sont
                  adaptées à vos besoins.
                </dd>
                <dd className="mt-2 text-base text-gray-500">
                  Sur une table de massage, le patient s’étend confortablement,
                  protégé d’une couverture. Les séances, à raison d’une fois
                  tous les sept jours, durent entre 35 min et 60 min. A noter
                  qu’un traitement complet pour un problème léger ne dépasse
                  rarement 3 ou 4 séances.
                </dd>
                <dd className="mt-2 text-base text-gray-500">
                  Une des particularités de la technique Bowen est le calme dans
                  lequel se déroulent les séances : il n’y a pas de fond sonore,
                  aucune odeur n’est diffusée et il n’y a pas d’échange entre la
                  praticienne et le patient.
                </dd>
                <dd className="mt-2 text-base text-gray-500">
                  Entre les séries de manipulations légères, une pause de 2 ou
                  plusieurs minutes est essentielle afin de laisser le système
                  nerveux sympathique (état de stress) passer en mode
                  parasympathique (état de profonde relaxation). Plongé dans un
                  état de profonde relaxation, il est fréquent que le
                  patients’endorme lors la séance.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto px-4 divide-y-2 divide-gray-200 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-gray-900">
            Recommandations
          </h2>
          <div className="mt-6 pt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900"></dt>
                <dd className="mt-2 text-base text-gray-500">
                  Avec la technique Bowen, nous approchons de très près du langage du corps.
                  Dans les jours qui suivent la séance, votre corps travaille pour corriger tous ses
                  déséquilibres et se trouve ainsi dans un processus de détoxification.
                </dd>
              </div>

              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900"></dt>
                <dd className="mt-2 text-base text-gray-500">
                  <p>
                    La technique Bowen est bénéfique à tout le monde. Elle
                    contribue aussi à maintenir une bonne santé et elle est
                    préventive.
                  </p>
                </dd>
              </div>
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900"></dt>
                <dd className="mt-2 text-base text-gray-500">
                  Il est conseillé, entre autres :
                  <ul>
                    <li>De bouger toutes les demi-heures le jour de la séance</li>
                    <li>De boire de l’eau, quelques verres par jour, à petites gorgées</li>
                    <li>D’éviter les douches et les bains très chauds</li>
                    <li>De ne pas combiner cette thérapie avec d’autres thérapies dans les jours qui suivent la séance.</li>
                  </ul>
                </dd>
              </div>
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900"></dt>
                <dd className="mt-2 text-base text-gray-500">
                  <p>
                    Une séance de technique Bowen ne remplace pas une visite
                    médicale et aucun traitement médical ne doit être modifié ou
                    interrompu.
                  </p>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BowenTherapy;
