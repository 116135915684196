function animation() {
  return `
    bg-gradient-to-r
    from-primary-900
    via-primary-600
    to-primary-900
    background-animate
  `;
}

export {animation};
