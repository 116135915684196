import React from 'react';
import PricingTableItem from './PricingTableItem';
import {PricingType} from './PricingTableItem';

type PricingTypeExtended = PricingType & { id: number };

const pricingTypes: Array<PricingTypeExtended> = [
  {
    id: 0,
    title: 'Technique Bowen',
    description:
      'La Thérapie Bowen est une technique holistique de manipulations douces et précises des muscles, tendons et nerfs. L’essence même de cette méthode est de stimuler l’organisme afin de redonner au corps son pouvoir d’auto-guérison.',
    session: 'par seance',
    sessionPrice: '50',
  },
  {
    id: 1,
    title: 'Massage relaxant ou harmonisant',
    description: 'Par grands mouvements, fluides et délicats, respectant la peau et les reliefs du corps, ce massage devient une source de sérénité et de paix intérieure.',
    session: '60min',
    sessionPrice: '65',
  },
  {
    id: 2,
    title: 'Massage tonifiant',
    description: 'En soulageant les tensions musculaires, évacuant le stress, éliminant les courbatures, cette technique dynamique maximise les capacités physiques et apporte de la tonicité.',
    session: '60min',
    sessionPrice: '65',
  },
  {
    id: 3,
    title: 'Massage de dos',
    description: 'Cette partie du corps est souvent exposée aux tensions musculaires. Un massage régulier contribuera à améliorer le maintien et la bonne posture de ce qui est considéré comme le pilier du corps humain.',
    session: '40min',
    sessionPrice: '40',
  },
  {
    id: 4,
    title: 'Massage demi-jambes et pieds',
    description: 'Les pieds constituent la partie du corps qui supporte la charge pondérale et ce soin particulier permet de rétablir l’équilibre et de remédier à différents dysfonctionnements.',
    session: '40min',
    sessionPrice: '45',
  }, {
    id: 5,
    title: 'Massage jambes lourdes',
    description: 'Pour lutter contre la sensation de lourdeur, de fourmillements, cette technique efficace permet de drainer les surplus de fluides, d’améliorer la circulation sanguine et d’éprouver une agréable sensation de légèreté.',
    session: '40min',
    sessionPrice: '45',
  },
  {
    id: 6,
    title: 'Massage à la bougie',
    description: 'Conçu pour stimuler les sens, j’utilise une bougie à base d’une cire spéciale, du beurre de Karité et des huiles essentielles\n' +
        'Lorsqu’elle atteint la température optimale, la cire de la bougie se transforme en huile et le massage se transforme en rituel inoubliable qui vous fait ressentir un confort extraordinaire, une sensation unique pour le corps et l’âme',
    session: '60min',
    sessionPrice: '70',
  },
  {
    id: 7,
    title: 'Massage aux pierres chaudes',
    description: 'La combinaison de la chaleur des roches basaltiques, la subtilité de l’huile et le parfum particulier feront de cette séance une véritable expérience sensorielle.',
    session: '60min',
    sessionPrice: '70',
  },
  {
    id: 8,
    title: 'Massage abdominal',
    description: 'Soulage la constipation et le ballonnement, \n' +
        'Évacue le stress et les toxines\n' +
        'Fait « digérer » nos émotions accumulées et induit une détente générale.',
    session: '30min',
    sessionPrice: '35',
  },
  {
    id: 9,
    title: 'Massage facial et de la tête',
    description: 'Antidote pour le stress, soulage les maux de tête \n' +
        'Dégage les voies nasales et sinusales\n' +
        'Grâce à la vascularisation, le visage se libère de ses tensions, le cuir chevelu sera plus souple et les cheveux plus résistants\n' +
        'Je conseille au client de venir démaquillé afin de réaliser un bon massage et de mentionner s’il existe des allergies ou des sensibilités particulières.\n',
    session: '40min',
    sessionPrice: '40',
  },

  {
    id: 10,
    title: 'Pose des ventouses',
    description: "Cette technique millénaire qui remonte à -400 ans avant J.C. s'avère encore aujourd'hui très efficace. " +
    "Les ventouses peuvent s'appliquer \n" +
        '"en ventouses mobile" ; on les fait glisser pour avoir un effet de massage ou \n' +
        '"en ventouse flash" ; la succion est faite rapidement et de manière répétée\n' +
        '\n',
    session: '30min',
    sessionPrice: '30',
  },

  // Les ventouses - cette technique millénaire remonte à -400 ans avant J.C.
    
  // 📌Quels en sont les bénéfices?


// Elles peuvent :
// - Améliorer les performances sportives
// - Traiter les maux de dos
// - Soulager  les rhumatismes
// - Réduire les tensions musculaires en périodes de stress ou de journées très chargées 
// - Favoriser la circulation et le retour veineux 
// - Accélérer l'élimination des toxines
// - Stimuler le flux d'énergie 
//      🔥 Appliquer
//         - en ventouses mobile- la glisser pour avoir un effet de massage ou
//         - en ventouse flash - la succion est faite rapidement et de manière répétée.... 
//      la pose de ventouses s'avère encore aujourd'hui très efficace !!! 🔥
// -15% pour les rdv prise en décembre 

// {
//     id: 1,
//     title: 'Bowen',
//     description:
//       'La Thérapie Bowen est une technique holistique de manipulations douces et précises des muscles, tendons et nerfs. L’essence même de cette méthode est de stimuler l’organisme afin de redonner au corps son pouvoir d’auto-guérison.',
//     session: 'par seance',
//     sessionPrice: '30',
//   },
//   {
//     id: 2,
//     title: 'Massage',
//     description:
//       'On pense que le massage améliore la circulation sanguine et lymphatique. Cela est probablement dû en partie à la manipulation physique des tissus mous et en partie aux produits chimiques libérés dans le cadre de la réponse de relaxation.',
//     session: 'par heure',
//     sessionPrice: '50',
//   },
];


const PricingTable = () => {
  return (
    <div className="min-w-screen  px-5 py-5">
      {pricingTypes.map((t) => (
        <PricingTableItem key={t.id} item={t} />
      ))}
    </div>
  );
};

export default PricingTable;
