// import {ReactComponent as Logo} from './logo/LogoGoldSVG4.svg';
import LogoPhoto from './logo/logoclepsydra.png';
import React from 'react';

function Logo(props:any) {
  return <img src={LogoPhoto} {...props} />;
}

import Mamichula from './images/mamichula.jpg'; // Tell webpack this JS file uses this image
import MainProfilePhoto from './images/mainProfilePhoto.jpg'; // Tell webpack this JS file uses this image
import ClepsidraPhoto from './images/clepsidra.jpeg'; // Tell webpack this JS file uses this image
import MassagePhoto from './images/massage.jpeg'; // Tell webpack this JS file uses this image
import Bowen from './bowen.jpg'; // Tell webpack this JS file uses this image

export {Logo, Mamichula, MainProfilePhoto, ClepsidraPhoto, MassagePhoto, Bowen};
