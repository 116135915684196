import React from 'react';


function Title() {
  return (
    <main className={`mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 `}>
      <div className="flex flex-col items-center align-center mt-10">
        <h3 className="titlefont text-2xl sm:text-4xl md:text-4xl lg:text-4xl tracking-tight mb-4 text-primary-100 ">
                  Bienvenue à</h3>
        {/* <h3 className="titlefont text-4xl sm:text-4xl md:text-4xl  text-primary-100">a</h3>*/}
        <h1 className="titlefont text-5xl md:text-5xl lg:text-8xl mb-4 sm:mb-0 text-primary-100">Clepsydra Bien-Etre</h1>
        <h1 className="titlefont text-3xl md:text-4xl lg:text-4xl  text-primary-100">Thérapie <span className="titlefont text-3xl md:text-4xl lg:text-4xl ml-2 text-primary-100">Bowen</span></h1>

        {/* <p className="mt-3 text-base  sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-4xl lg:mx-0">*/}
        {/*            Mon espace holistique où je vous invite à essayer le massage*/}
        {/*            comme thérapie pour le corps et l’âme*/}
        {/* </p>*/}
        {/* <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-4xl lg:mx-0">
                Vous chercher une idée de cadeau originale ?
              </p> */}
        {/* <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-4xl lg:mx-0">*/}
        {/*            Offrez un moment de bien-être et de détente aux personnes qui*/}
        {/*            vous sont chères !*/}
        {/* </p>*/}

      </div>
    </main>
  );
}

export default Title;
