import React from 'react';
import {Router} from '@reach/router';
import './App.css';
import Home from './pages';
import AboutPage from './pages/about';
import BienEtrePage from './pages/bienetre';
import ContactPage from './pages/contact';
import Pricing from './pages/pricing';
import Therapy from './pages/therapy';
import NotFoundPage from './pages/404';

function App() {
  return (
    <Router>
      <Home path="/" />
      <AboutPage path="/about" />
      <ContactPage path="/contact" />
      <BienEtrePage path="/bienetre" />
      <Pricing path="/pricing" />
      <Therapy path="/therapy" />
      <NotFoundPage path="*" />
    </Router>
  );
}

export default App;
