import React from 'react';
import BienEtre from '../components/BienEtre';
import DefaultLayout from '../layout/DefaultLayout';

import {RouteComponentProps} from '@reach/router';

type BienEtreProps = {
  children?: any;
} & RouteComponentProps;

export default function BienEtrePage(props: BienEtreProps) {
  return (
    <DefaultLayout title={'Bien-Être'} route={'bienetre'}>
      <BienEtre />
    </DefaultLayout>
  );
}
