import React from 'react';

type AppContainerProps = {
  children: any;
};

const AppContainer = (props: AppContainerProps) => {
  return <div className="container mx-auto px-4 sm:px-6 lg:px-8">{props.children}</div>;
};

export default AppContainer;
