export default function MainContentHeader() {
    return (
        <div className="relative bg-white ">
            <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:items-start lg:gap-24 lg:px-8">
                <div className="relative sm:py-16 lg:py-0">
                    <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
                        <div className="absolute inset-y-0 right-1/2 w-full rounded-r-3xl lg:right-72" />
                        <svg
                            className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                            width={404}
                            height={350}
                            fill="none"
                            viewBox="0 0 404 392"
                        >
                            <defs>
                                <pattern
                                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
                        </svg>
                    </div>
                    <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
                        {/* Testimonial card*/}
                        <div className="relative overflow-hidden rounded-2xl pt-8 pb-10 shadow-xl">
                            <div className="absolute inset-0 bg-primary-600 mix-blend-multiply" />
                            <div className="absolute inset-0 bg-secondary-to-t from-primary-600 via-primary-600 opacity-90" />
                            <div className="relative px-8">
                                <blockquote className="mt-8">
                                    <div className="relative text-lg font-medium text-white md:flex-grow">
                                        <svg
                                            className="absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-primary-100"
                                            fill="currentColor"
                                            viewBox="0 0 32 32"
                                            aria-hidden="true"
                                        >
                                            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                        </svg>
                                        <p className="relative">
                                            Mon espace holistique dans lequel je vous invite à aborder la technique Bowen et le massage comme thérapies naturelles pour le corps et l’âme.
                                        </p>
                                    </div>

                                    {/* <footer className="mt-4">
                      <p className="text-base font-semibold text-indigo-200">Sarah Williams, CEO at Workcation</p>
                    </footer> */}
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                    {/* Content area */}
                    <div className="pt-12 sm:pt-16 lg:pt-20">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            Offrez un moment de bien-être et de détente aux personnes qui vous sont chères.
                        </h2>
                        <div className="mt-6 space-y-6 text-gray-500">
                            <p className="text-lg">
                                Il arrive que l’on soupire en se demandant si la technique Bowen est faite pour nous ou si un bon massage nous ferait du bien.
                            </p>
                            <p className="text-base leading-7">
                                Lors d’une séance, la peau est le principal messager, le messager qui fait le lien entre les mains du praticien, votre corps et le ressenti. En y ajoutant la confiance et le savoir, vous aurez la réponse à ces questions.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:items-center lg:px-8">
                <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                    <div className="mt-6 space-y-6 text-gray-500">
                        <p className="text-base leading-7">
                            A l’écoute de vos attentes, avec bienveillance et respect, je vous amène à la rencontre de vous-même, à récupérer le dynamisme et la joie indispensables à la vie quotidienne.
                        </p>
                        <p className="text-base leading-7">
                            Vous pouvez profiter du sport, des promenades en forêt ou d’un bain décontractant, mais le massage reste le meilleur accès à votre éveil sensoriel, révélant une harmonie intérieure et énergétique.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
