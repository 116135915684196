/* This example requires Tailwind CSS v2.0+ */
import { MailIcon, PhoneIcon } from '@heroicons/react/solid';
import React from 'react';
import Title from './Title';
// import {Logo, MainProfilePhoto} from '../assets';
import { Mamichula, MassagePhoto } from '../assets';
import { Link } from '@reach/router';
import { Routes } from '../lib/routes';
import { animation } from '../lib/animationUtils';
import HeaderNav from './HeaderNav';

const profile = {
  name: 'Batacliu Gabriela',
  email: 'rendezvous@clepsydra-bien-etre.be',
  avatar: Mamichula,
  backgroundImage: MassagePhoto,
};


export default function Headerv2() {
  return (
    <div>

      <div className={`${animation()}`}>
        <HeaderNav />
        <div className={`bg-transparent h-64 flex center`}>
          <Title />
          {/* <img className="h-48 w-full object-cover lg:h-96" src={profile.backgroundImage} alt=""/>*/}
        </div>
      </div>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          <div className="flex">
            <img className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32" src={profile.avatar}
              alt="" />
          </div>
          <div
            className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div className="sm:hidden md:block mt-6 min-w-0 flex-1">
              <h1 className="text-2xl font-bold text-gray-900 truncate">{profile.name}</h1>

              <span className="inline-flex items-center rounded-md bg-primary px-2.5 py-0.5 text-sm font-medium ">
                Praticienne Technique Bowen
              </span>
              <span className="inline-flex items-center rounded-md bg-primary px-2.5 py-0.5 lg:ml-2 text-sm font-medium ">
                Praticienne Massage Bien-être
              </span>
            </div>

            <div
              className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
              <div
                className="inline-flex justify-center px-4 py-2 border border-primary-100 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                <Link className="flex " to={Routes.Contact}>
                  <MailIcon className="-ml-1 mr-2 h-5 w-5 text-primary-600" aria-hidden="true" />
                  <PhoneIcon className="-ml-1 mr-2 h-5 w-5 text-primary-600" aria-hidden="true" />
                  <span className="text-primary-900">Contactez moi!</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden sm:block md:hidden mt-6 min-w-0 flex-1">
          <h1 className="text-2xl font-bold text-gray-900 truncate">{profile.name}</h1>
        </div>
      </div>
    </div>
  );
}
