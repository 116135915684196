import React from 'react';

export type PricingType = {
  title: string;
  description: string;
  session: string;
  sessionPrice: string;
  // subscription: string;
};

type PriceTableItemProps = {
  item: PricingType;
};

const PricingTableItem = (props: PriceTableItemProps) => {
  return <>
    <div className="w-full mx-auto flex items-center align-center flex-col bg-white px-5 py-10 text-gray-600 mb-10 rounded-md shadow-md shadow-gray-600">

      <h1 className="text-4xl font-bold mb-5">{props.item.title}</h1>
      <h3 className="text-md font-medium mb-5">
        {props.item.description}
      </h3>

      <div className="flex items-center justify-between rounded-sm shadow-md shadow-primary-100 p-2">
        <h2 className="text-center mr-4">
        Une séance
        </h2>
        <h3 className="text-center font-bold text-2xl mr-1">
          {props.item.sessionPrice}€
        </h3>
        <ul className="text-sm">
          <li className="leading-tight">
            <i className="mdi mdi-check-bold text-md">
              /{props.item.session}
            </i>
          </li>
        </ul>
      </div>
    </div>
  </>;
};

// eslint-disable-next-line no-unused-vars
const PricingTableItem2 = (props: PriceTableItemProps) => {
  return (
    <>
      <div className="w-full mx-auto bg-white px-5 py-10 text-gray-600 mb-10">
        <div className="max-w-5xl mx-auto md:flex">
          <div className="md:w-1/4 md:flex md:flex-col">
            <div className="text-left w-full flex-grow md:pr-5">
              <h1 className="text-4xl font-bold mb-5">{props.item.title}</h1>
              <h3 className="text-md font-medium mb-5">
                {props.item.description}
              </h3>
            </div>
            {/* <div className="w-full mb-2">
              <p className="text-xs">*Lorem ipsum sit amet</p>
            </div> */}
          </div>
          <div className="md:w-3/4">
            <div className="max-w-4xl mx-auto md:flex">
              <div className="w-full md:w-1/3 md:max-w-none bg-white px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto md:my-2 rounded-md shadow-lg shadow-gray-600 md:flex md:flex-col">
                <div className="w-full flex-grow">
                  <h2 className="text-center font-bold uppercase mb-4">
                    Une séance
                  </h2>
                  <h3 className="text-center font-bold text-4xl mb-5">
                    {props.item.sessionPrice}€
                  </h3>
                  <ul className="text-sm mb-8">
                    <li className="leading-tight">
                      <i className="mdi mdi-check-bold text-lg"></i>
                      {props.item.session}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="w-full md:w-1/3 md:max-w-none bg-white px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto md:my-2 rounded-md shadow-lg shadow-gray-600 md:flex md:flex-col">*/}
      {/*  <div className="w-full flex-grow">*/}
      {/*    <h2 className="text-center font-bold uppercase mb-4">*/}
      {/*      Cartes 5 + 1 séance*/}
      {/*    </h2>*/}
      {/*    /!* <h3 className="text-center font-bold text-4xl mb-5">*!/*/}
      {/*    /!*  {props.item.subscription}€*!/*/}
      {/*    /!*  <span className="text-sm">/séance</span>*!/*/}
      {/*    /!* </h3>*!/*/}
      {/*    <ul className="text-sm mb-8">*/}
      {/*      <li className="leading-tight">*/}
      {/*        <i className="mdi mdi-check-bold text-lg"></i> Vous gagnez*/}
      {/*        une séance gratuite*/}
      {/*      </li>*/}
      {/*      /!* <li className="leading-tight">*/}
      {/*        <i className="mdi mdi-check-bold text-lg"></i> Dolor sit*/}
      {/*        amet*/}
      {/*      </li>*/}
      {/*      <li className="leading-tight">*/}
      {/*        <i className="mdi mdi-check-bold text-lg"></i> Consectetur*/}
      {/*      </li>*/}
      {/*      <li className="leading-tight">*/}
      {/*        <i className="mdi mdi-check-bold text-lg"></i> Adipisicing*/}
      {/*      </li>*/}
      {/*      <li className="leading-tight">*/}
      {/*        <i className="mdi mdi-check-bold text-lg"></i> Much*/}
      {/*        more...*/}
      {/*      </li> *!/*/}
      {/*    </ul>*/}
      {/*  </div>*/}
      {/* </div>*/}

    </>
  );
};

export default PricingTableItem;
