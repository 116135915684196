import React from 'react';
import PricingTable from '../components/PricingTable';
import DefaultLayout from '../layout/DefaultLayout';
import {RouteComponentProps} from '@reach/router';

type PricingProps = {
  children?: any;
} & RouteComponentProps;

const Pricing = (props: PricingProps) => {
  // const {popularTypes} = props;

  return (
    <DefaultLayout title={'Prix'} route={'pricing'}>
      <div className="pt-12 px-4 sm:px-6 lg:px-8 lg:pt-20">
        <div className="text-center">
          {/* <h2 className="text-lg leading-6 font-semibold text-primary-100 uppercase tracking-wider">
            Pricing
          </h2> */}
          <p className="mt-2 text-3xl font-extrabold text-primary sm:text-4xl lg:text-5xl">
            Le juste prix pour vous
          </p>
          {/* <p className="mt-3 max-w-4xl mx-auto text-xl text-gray-300 sm:mt-5 sm:text-2xl">
              Lorem ipsum dolor si t, amet consectetur adipisicing elit. Velit
              numquam eligendi quos odit doloribus molestiae voluptatum.
            </p> */}
        </div>
      </div>

      <PricingTable />
    </DefaultLayout>
  );
};

// export async function getServerSideProps(context) {
//   const pricingTypes = await useGetPricing();
//   const popularTypes = await useGetPopularPricing();
//   return {
//     props: {
//       pricingTypes,
//       popularTypes,
//     }, // will be passed to the page component as props
//   };
// }

// export async function getStaticProps(context) {
//   const pricingTypes = await useGetPricing();
//   const popularTypes = await useGetPopularPricing();
//   return {
//     props: {
//       pricingTypes,
//       popularTypes,
//     },
//     // Next.js will attempt to re-generate the page:
//     // - When a request comes in
//     // - At most once every second
//     revalidate: 1, // In seconds
//   };
// }

export default Pricing;
