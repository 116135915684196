/* This example requires Tailwind CSS v2.0+ */
import {RoutesArray} from '../lib/routes';
import React from 'react';
import {Logo} from '../assets';
import {Link} from '@reach/router';


export default function HeaderNav() {
  return (
    <header >
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="w-full py-6 lg:pb-0 lg:pt-6 flex items-center justify-between border-b border-primary-100 lg:border-none">
          <div className="flex items-center">
            <Link to="/">
              <Logo className="h-16 w-auto"/>
            </Link>
            <div className="hidden ml-10 space-x-8 lg:block">
              {RoutesArray.map((link) => (
                <Link key={link.ID} to={link.Location} className="text-base font-medium text-white hover:text-primary-100">
                  {link.Name}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
          {RoutesArray.map((link) => (
            <Link key={link.ID} to={link.Location} className="text-base font-medium text-white hover:text-primary-100">
              {link.Name}
            </Link>
          ))}
        </div>
      </nav>
    </header>
  );
}
