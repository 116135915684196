import React from 'react';
import AppContainer from './AppContainer';
import Footer from './Footer';
import {Helmet} from 'react-helmet';
import Headerv2 from './Headerv2';

type HelmetProps = {
    title?: string;
    route?: string;
}

type DefaultLayoutProps = {
    children: any;
} & HelmetProps;

const DefaultLayout = (props: DefaultLayoutProps) => {
  // const user = null;
  return (
    <>
      <Helmet>
        <title>{props.title || 'Clepsydra Bien Être'}</title>
        <link rel="canonical" href={`https://www.clepsydra-bien-etre.be${props.route || ''}`} />
      </Helmet>
      {/* <Header />*/}
      <Headerv2/>
      <AppContainer>{props.children}</AppContainer>
      <Footer/>
    </>
  );
};

// </div></div>Mobile menu, show/hide based on menu open state.

//     Entering: "duration-150 ease-out"
//       From: "opacity-0 scale-95"
//       To: "opacity-100 scale-100"
//     Leaving: "duration-100 ease-in"
//       From: "opacity-100 scale-100"
//       To: "opacity-0 scale-95"
//   -->

export default DefaultLayout;
