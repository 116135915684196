/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const BienEtre = () => {
  return (
    <div>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto  px-4 divide-y-2 divide-gray-200  sm:px-6 lg:px-8">
          <h2 className="text-3xl mt-6 font-extrabold text-gray-900">
            Les bienfaits de massage bien-être
          </h2>
          <div>
            <h3 className="text-gray-500 pt-4">
              Comme l'exercice physique, le massage bien-être est une pratique
              habituelle de santé
            </h3>
            <h3 className="text-gray-500 pt-4">
              Particulièrement relaxant, le massage bien-être aide votre corps à
              retrouver son équilibre naturel
            </h3>
          </div>
          <div className="mt-6 pt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  1. Réduire les spasmes et dénouer les tensions musculaires
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Comment obtenir l’effet?
                </dd>
                <dd className="mt-2 text-base text-gray-500">
                  L’application d’une pression permet de prendre conscience
                  qu’il y a bien une tension dans une zone particulière
                </dd>
                <dd className="mt-2 text-base text-gray-500">
                  Par l’application d’une friction sur la zone sensible
                </dd>
                <dd className="mt-2 text-base text-gray-500">
                  En stimulant les points douloureux, qui permet de relâcher les
                  contractions des muscles concernés
                </dd>
              </div>

              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  2. Améliorer l’efficacité du système immunitaire
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  A travers votre corps il existe un vaste système de vaisseaux
                  qui est à peu près parallèle à l’appareil de la circulation
                  sanguine.
                </dd>
                <dd className="mt-2 text-base text-gray-500">
                  Il s’agit du système lymphatique. Ce dernier est rempli d’un
                  fluide responsable d’enlever et d’éliminer les nombreux
                  organismes, bactéries et virus de votre corps.
                </dd>
                <dd className="mt-2 text-base text-gray-500">
                  Comment obtenir l’effet?
                </dd>
                <dd className="mt-2 text-base text-gray-500">
                  Le mouvement, la contraction musculaire et le massage sont les
                  moyens qui aident la lymphe à être transportée de la façon la
                  plus efficace dans le corps. En effet, le système lymphatique
                  ne dispose pas de sa propre pompe comme l’appareil
                  circulatoire du sang dispose du cœur.
                </dd>
              </div>
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  3. Favoriser la circulation sanguine
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Les veines de petites valves à sens unique empêchent le
                  sang de retourner dans la mauvaise direction. C’est la raison
                  pour laquelle il faut masser dans le sens de la circulation
                  sanguine vers le cœur.
                </dd>
                <dd className="mt-2 text-base text-gray-500">
                  Diffuser le sang contre ces valves risquerait de les
                  endommager et provoquer l’apparition de varices.
                </dd>
                <dd className="mt-2 text-base text-gray-500">
                  Comment obtenir l’effet?
                </dd>
                <dd className="mt-2 text-base text-gray-500">
                  Certains mouvements de massages effectués dans la bonne
                  direction peuvent améliorer la circulation.
                </dd>
              </div>
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  4. Accélérer la régénération des tissus de votre organisme
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  En favorisant l’afflux de sang riche en nutriments dans les
                  zones qui se rétablissent d’une douleur et en facilitant le
                  nettoyage des toxines, le massage accélère la guérison et aide
                  à recouvrer plus rapidement leur élasticité et leurs
                  fonctions.
                </dd>
              </div>

              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  5. Garder une peau jeune et saine
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Le massage peut aider votre peau à mieux respirer car il
                  favorise l’élimination des cellules mortes, de la sueur, tout
                  en apportant de nombreux éléments nutritifs (crèmes, huiles,
                  lotions) qui seront absorbés par la peau.
                </dd>
              </div>

              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  6. Apporter une profonde relaxation
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Le corps retrouve sa vitalité et est débarrassé des
                  désagréments d’ordre physique. Votre esprit est libéré de tous
                  ses troubles nerveux.
                </dd>
              </div>
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  7. Améliorer l’apparence physique
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Le massage peut améliorer la beauté de votre corps un aspect
                  beaucoup plus revitalisé.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BienEtre;
